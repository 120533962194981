#app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  background-image: url(../img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #d0d4dd;
  h1 {
    width: 95%;
    position: fixed;
    top:0px;
    left:0;
    right:0;
    margin: auto;
    @include media(TB){
      top: -10px;
    }
  }
  footer {
    position: fixed;
    bottom:0;
    width: 100%;
  }
  .copy {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 10px;
    text-align: center;
    padding-top: 40px;
    font-family: $heading-font;
  }
  .menu {
    width: 95%;
    ul {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      @include media(TB){
        padding-top: 40px;
      }
      li {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
        padding: 0 10px;
        font-family: $heading-font;
        @include media(TB){
          font-size: 8.0rem;
          font-weight: 800;
        }
        a {
          color: #d0d4dd;
          text-decoration: none;
          &:hover {
            text-decoration: line-through;
          }
        }
        span {
          text-decoration: line-through;
        }
      }
    }
  }
  .pres {
    text-align: center;
    font-size: 1.2rem;
    @include media(TB){
      font-size: 1.4rem;
    }
  }
}
